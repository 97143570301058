import React, { useCallback } from "react";
import './custom.css';
import { Drawer, Hidden, Toolbar,Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Truncate from 'react-truncate-html';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import axios from 'axios'
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import truncate from 'truncate-html'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const drawerWidth = "325px";

const useStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      // width: '100px',
      flexShrink: 0,
    },
  },
  avatarColor:{
    color: "white",
    backgroundColor:"#4648c2"
  },
  button:{
      textAlign:"center",
  },
 // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
    // position:'absolute',
    left:'auto',
    width: drawerWidth
    },
    [theme.breakpoints.down('xs')]: {
      left:'auto',
      width:"-webkit-fill-available"
    }

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
});

class EmailList extends React.Component {
  constructor(props){
    super(props)
    this.state={
      mid:null,
      messages: [],
      Files:[],
      folder:[],
      deleteDialogOpen:false,
      deleteId:""
    }
  }

  getEmailList=()=>{
    // const url = process.env+ this.props.mid
    axios.get(process.env.REACT_APP_OMS_MESSAGE_URL, {
      params: {
        mid: this.props.mid
      },
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'No-Store'
    }})
      .then(res => {
        //console.log('Response received!')
       //console.log(res.data)
       this.setFolders(res.data.message)
       this.setState({
         messages:res.data.message,
       })
      })
    .catch(error => {
      //console.log('Printing error')
      //console.log(error);
    });
  }
  clearFolder = ()=>{
    this.setState({
      folder:[]
    })
  }
  setFolders = (data) =>{
    if(this.props.folder === "Inbox"){
      //console.log("Inside setFolder() inbox")
      data.map((value,i) => {
        value.recipients.recipient.map((val,i)=>{
          if(val.mid===this.props.mid){
            this.state.folder.push(value)
          }
        })
      })
      //console.log("Inside setFolder() inbox has changed values")
    }
    else if(this.props.folder === "Sent"){
      //console.log("Inside setFolder() sent")
      data.map((value,i) => {
        if(value.sender.mid === this.props.mid){
          this.state.folder.push(value)
        }
      })
      //console.log("Inside setFolder() sent has changed values")
    }
  }
  readMessage = (messageId) =>{
    const url = process.env.REACT_APP_OMS_MESSAGE_URL +messageId 
    axios.get(url,{
      headers:{
        "Authorization" : "Bearer " + sessionStorage.getItem("token"),
        'Cache-Control': 'No-Store'
      }
    })
    .then((res)=>{
      //console.log("message read successfully")
      })
    .catch((err)=>{
      //console.log("Printing error: " + err)
    })
  }
  setEmailProps = (messageId, name, subject, body, date, attachment)=>{
    //console.log("inside setEmailProps with attachment")

    // const mimeType = [];
    const att_Id = [];
    attachment.map((value,index) =>{
          att_Id.push(value.attachment_id)
          // mimeType.push(value.mim)
    })
    const files = []
      att_Id.map((value,i)=>{
        let url = process.env.REACT_APP_OMS_ATTACHMENTS_URL + value
        axios.get(url,{
          responseType:"blob",
          headers:{
            "Authorization" : "Bearer " + sessionStorage.getItem("token"),
            'Cache-Control': 'No-Store'
          }
        })
        .then((res)=>{
          files.push(URL.createObjectURL(res.data))
          this.setState({
            Files:files
          })
           //calling MailboxList functions
          
          this.props.getEmailProps(name,subject,body, date, this.state.Files)
          this.props.handleInitialEmailView(true)
          this.props.handleMobileEmailView(true)
          this.readMessage(messageId)
        })
        .catch((err)=>{
          //console.log("Printing error: " + err)
        })
      })
 
    
  }
  setEmailProps1 = (messageId, name, subject, body, date)=>{
    //console.log("inside setEmailProps withouts attachment")
      //calling MailboxList functions
      this.readMessage(messageId)
      this.props.getEmailProps(name,subject,body,date,[])
      this.props.handleInitialEmailView(true)
      this.props.handleMobileEmailView(true)
  }
  handleClose=()=>{
    this.setState({
      deleteDialogOpen:false
    })
  }
  handleOpen=(event,value)=>{
    event.stopPropagation()
   //console.log("Id to be deleted : ", value)
    this.setState({
      deleteDialogOpen:true,
      deleteId:value
    })
  }
  handleDelete = () =>{
    // event.stopPropagation()
    const url = process.env.REACT_APP_OMS_MESSAGE_URL + this.state.deleteId
    axios.delete(url,{
      headers:{
        "Authorization": "Bearer " + sessionStorage.getItem('token'),
        'Cache-Control': 'No-Store'
      }
    })
    .then((res)=>{
      //console.log("Message deleted successfully")
      this.setState({
        deleteDialogOpen:false,
        deleteId:""
      })
      setTimeout(()=>{
        this.clearFolder()
        this.getEmailList()
        this.props.handleMobileEmailView(false)//to hide current showing email after delete
      },100)
      

    })
    .catch((err)=>{
      alert("There was an error while deleting the message." )
    })
  }
  truncateBody = (str) => {
    //console.log("value.body : ",str)
    if(str.includes("<title>")){
      //console.log("inside title includes true condition")
      const index = str.indexOf("</title>")
      const substr = str.substring(index+9,str.length)
      return ReactHtmlParser(truncate(substr,10,{byWords:true,stripTags: true}))
    }
    else {
      return ReactHtmlParser(truncate(str,10,{byWords:true,stripTags: true}))
    }
    
    
    

  }
  truncateSub = (str) => {
    return str.length > 35 ? str.substring(0, 32) + "..." : str;
  }
  truncateDate = (date)=>{
    let sdate = "" + date
    let time = sdate.substring(11,16)
    sdate = sdate.substring(5,10).replace("-","/")
    return sdate +" "+ time
  }
  
  render(){
    const { classes } = this.props;
  return(
      <div>
          <Drawer
              variant="permanent"
              classes={{
              paper: classes.drawerPaper,
              }}
          >
          <Toolbar/>
          <div>
      <List className={classes.root}>
       { this.state.folder.map((value,i) =>
               <div>
              <ListItem button alignItems="flex-start" key={i}
              onClick={()=>{
                if(value.attachments){
                  //console.log(value.message_id)
                  {this.setEmailProps(value.message_id, value.sender.name, value.subject, value.body, value.date_created, value.attachments.attachment)}
                }
                else{
                  //console.log("no attachment found")
                 {this.setEmailProps1(value.message_id, value.sender.name, value.subject, value.body, value.date_created)}
                }
                }}>
              <ListItemAvatar>
                <Avatar className={classes.avatarColor} alt={value.sender.name} src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={6}>
                    <Typography noWrap="true">
                    {value.sender.name}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{
                      position:"absolute",
                      right:"20px"
                    }}>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      style={{
                        fontStyle:"italic"
                      }}
                    >
                    {this.truncateDate(value.date_created)}
                    </Typography>
                    </Grid>
                  </Grid>
                }
                  
                secondary={
                  <React.Fragment>
                    <Typography
                      // component="span"
                      variant="body2"
                      color="textPrimary"
                      noWrap="true"
                    >
                      {(value.subject)}
                      {/* <br/> */}
                    </Typography>
                    <Typography noWrap="true" >
                     <p style={{
                       "margin-block-start": "2px",
                       "margin-block-end": "2px",
                       "overflow": "hidden",
                       "textOverflow": "ellipsis",
                       "whiteSpace": "nowrap",
                       "width" :"180px"
                     }}>
                    { this.truncateBody(value.body)}
                      </p>
                      </Typography>
                      {/* <br/>        */}
                      <ListItemSecondaryAction>
                        <IconButton 
                        aria-label="delete" 
                        onClick ={(event)=>this.handleOpen(event,value.message_id)}
                        // onClick={(event)=>this.handleDelete(event,value.message_id)}
                        edge="end"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                  </React.Fragment>
                }
              />
            </ListItem>
              <Divider variant="inset" component="li" />
            </div>
       )}
      </List>
      <Dialog
        open={this.state.deleteDialogOpen}
        onClose={this.handleClose}
        aria-labelledby="deleteDialog"
        aria-describedby="deleteDialogDescription"
      >
        <DialogTitle id="deleteDialog">{"Are you sure you want to delete this message?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="deleteDialogDescription">
            This action will permanently delete this mail, 
            if you still wish to continue click Delete or click Cancel to go back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      </div> 
          </Drawer>
      </div>
      

  );
  }
}
export default withStyles(useStyles)(EmailList);