import React, { Component } from 'react';
import 'url-search-params-polyfill'; 
import axios from 'axios';
import qs from 'querystring';
import {Divider, Drawer, Hidden, CssBaseline, AppBar, Toolbar, IconButton, Typography, Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SessionTimeout from './SessionTimeout'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import MenuIcon from '@material-ui/icons/Menu';
import CreateIcon from '@material-ui/icons/Create';
import IdleTimer from 'react-idle-timer';
import EmailList from './EmailList';
import Email from './Email';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Compose from './Compose';


const drawerWidth = 220;

const useStyles = (theme) => ({
  root: {
    display: 'flex',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
      width: drawerWidth,
      flexShrink: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
     display: 'none',
  },
},
homeButton:{
  [theme.breakpoints.down('sm')]:{
    top:"-1px"
  },
  [theme.breakpoints.up('sm')]: {
    top:"8px"
 },
  [theme.breakpoints.up('md')]: {
   display: 'none',
},
},
 // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  button:{
    textAlign:"center",
},
});

class MailboxList extends Component {
    constructor(props){
      super(props)
      this.state={
        Access:false,
        userName:"",
        email:"",
        mid:"",
        mobileOpen : false,
        isCompose : true,
        senderName : "",
        subject:"",
        body:"",
        date:"",
        time:"",
        attachments:[],
        folder:"",
        selectedIndex:null,
        initialEmailView:false,
        showMobileEmail:false,
        timeout:1000 * 1800 * 1,
        showModal: false,
        userLoggedIn: false,
        isTimedOut: false
      }
      this.idleTimer = null
      this.onAction = this._onAction.bind(this)
      this.onActive = this._onActive.bind(this)
      this.onIdle = this._onIdle.bind(this)
      this.handleClose = this.handleClose.bind(this)
      this.handleLogout = this.handleLogout.bind(this)
    }
    componentWillUnmount(){
        sessionStorage.setItem('token',"")
        //console.log("Component removed with token value: "+sessionStorage.getItem('token'))
    }

    componentDidMount(){
      const url = decodeURIComponent(window.location.search)
      const params = new URLSearchParams(url);
      const paramToken = params.get('token')
      const paramEmail = params.get('email')
      const paramName = params.get('name')
      
           
          if((paramToken!==null && paramToken!=="") && (paramEmail!==null && paramEmail!== "") && (paramName!==null && paramName!=="")){
            this.checkToken(paramToken,paramName,paramEmail);
            }
          else{
            alert("You are unauthorized to use this service.")
            window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);
            // window.close()
          }
     
      }
    
    generateToken = () => {
      const body = { 
        client_id : process.env.REACT_APP_CLIENT_ID,          
        client_secret : process.env.REACT_APP_CLIENT_SECRET,
        grant_type : process.env.REACT_APP_GRANT_TYPE,
       }
      const config = {
          header : {
             'Content-Type': 'application/x-www-form-urlencoded',
             'Access-Control-Allow-Origin': '*',
             'Cache-Control': 'No-Store'
          }
      }
      axios.post(process.env.REACT_APP_GENERATE_TOKEN_URL, qs.stringify(body), config)
        .then(res => {
            sessionStorage.setItem('token',res.data.access_token)
            //console.log("Token : " +sessionStorage.getItem('token'))
      }) 
        .catch((error) => {
          //console.log('Printing error')
          //console.log(error);
        });
    }
   
    checkToken = (token,name,email) => {
         // Search for the MailBox
         //console.log("inside check token")
         axios.get(process.env.REACT_APP_OMS_MAILBOX_URL,{
          params:{
            space_id: process.env.REACT_APP_SPACE_ID,
            email: email
          },
          headers:{
            // 'Authorization': "Bearer " + sessionStorage.getItem('token'),
            'Authorization': "Bearer " + token,
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'No-Store'
          }
        })
        .then((res) => {
          sessionStorage.setItem('token',token)
           const tokenInterval = setInterval(()=>{
            this.generateToken();
            // console.log("hello")
          },1800000)
          if(res.data.mailbox.length !==0){
            this.setState({
              Access:true,
              userName: res.data.mailbox[0].name,
              email: res.data.mailbox[0].email,
              mid : res.data.mailbox[0].mid
            })
          //   setTimeout(()=>{
          //     console.log("Mailbox Id is Already present: " + this.state.mid + " name :" + this.state.UserName)
          // },100)
          }
          else{
             //console.log("mailbox isn't created")
             this.createMailbox(name,email); 
          }
        })
        .catch((err)=>{
          this.setState({
            Access:false
          })
          alert("You are unauthorized to use this service.")
          window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);
          // window.close()
          //console.log(err)
        })
    }
    createMailbox = (name,email) => {
        const bodyData = {
          "name" : name,
          "email":email,
          "space_id":process.env.REACT_APP_SPACE_ID
        }
        // Create a new mailbox
        axios.post(process.env.REACT_APP_OMS_MAILBOX_URL,bodyData,{
          headers:{
            'Authorization': "Bearer " + sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'No-Store'
          }
        })
        .then((res) => {
          //console.log("new mailbox created")
          this.claimMailbox(res.data.claim_token)
        })
        .catch((err)=>{
          alert("There was an error creating new mailbox.")
          window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);
          // window.close()
          //console.log("Error : " + err)
        })
        
    }
    claimMailbox = (claimToken) =>{
        
        // const claimUrl = "https://secure-messaging.optum.com/v2/mailboxes/claim/?claim_token=" + claimToken
        axios.put(process.env.REACT_APP_CLAIM_MAILBOX_URL, null, {
          params:{
            claim_token:claimToken
          },
          headers:{
            'Authorization': "Bearer " + sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'No-Store'
          }
        })
        .then((res)=>{
          //console.log("Claimed Mailbox Successfully")
          this.setState({
            Access:true,
            userName: res.data.name,
            email: res.data.email,
            mid: res.data.mid
          })
          // setTimeout(()=>{
          //   //console.log("New Mailbox created with ID: " + this.state.mid)
          //   alert("New MailBox have been created!")
          //   },100)
        })
        .catch((err)=>{
          //console.log("error : " + err)
          alert("There was an error claiming the mailbox")
          window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);
          // window.close()
        })
    }
    handleFolder = (value,i) => {
      this.setState({
        folder: value,
        isCompose:false,
        selectedIndex:i,
        initialEmailView:false,
        showMobileEmail:false
      })
     setTimeout(()=>{
       // For mobile view
       this.refs.emailListRefMobile.clearFolder();
       this.refs.emailListRefMobile.getEmailList();
       // for Desktop or other view
       this.refs.emailListRef.clearFolder();
       this.refs.emailListRef.getEmailList();
       //console.log("State of initialEmailView: ",this.state.initialEmailView)
       //console.log("\nState of showMobileEmail: ",this.state.showMobileEmail)
     },100)
    }
    handleDrawerToggle = () => {
      this.setState({
        mobileOpen : !(this.state.mobileOpen)
      })
    }
    showCompose = () => {
      this.setState({
        selectedIndex:null,
        isCompose: true
      })
    }
    hideCompose = () => {
      this.setState({
        isCompose:false
      })
    }
    handleInitialEmailView = (value) => {
    this.setState({
      initialEmailView:value
    })
    }
    handleMobileEmailView = (value) => {
        this.setState({
          showMobileEmail:value
        })
    }
    handleHomeButton = () => {
    this.setState({
      showMobileEmail:false,
      isCompose:true
    })
    }
    getEmailProps = (senderName,subject,body, sentDate,attachments) => {
    // console.log(Name,Subject, "Attchments: "+attachments)
        let sdate = "" + sentDate
        let time = sdate.substring(11,16)
        sdate = sdate.substring(0,10)
        this.setState({
          senderName: senderName,
          subject: subject,
          body: body, 
          date: sdate + " " + time,
          time: time,
          attachments: attachments,
        })
        // setTimeout(()=>{
        //   console.log(this.state.attachments)
        // },100)

    }
    //Timeout Functionality
    _onAction(e) {
      //console.log('user did something', e)
      this.setState({isTimedOut: false})
    }
   
    _onActive(e) {
      //console.log('user is active', e)
      this.setState({isTimedOut: false})
    }
   
    _onIdle(e) {
      //console.log('user is idle', e)
      const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
        window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);  
        //console.log("session timed out")
      } else {
        this.setState({showModal: true})
        this.idleTimer.reset();
        this.setState({isTimedOut: true})
      }
      
    }
    handleClose() {
      
      this.setState({showModal: false})
    }

    handleLogout() {
      this.setState({showModal: false})
      // this.props.history.push('/')
      window.location.replace(process.env.REACT_APP_REDIRECTED_APP_URL);
      //console.log("add timeout functionality")
    }


    render(){
      if(this.state.Access !== true){
        return null
      }
      const { classes } = this.props;
      const  theme = this.props.theme;
      const container = window !== undefined ? () => window.document.body : undefined;
      let mobileView;
      if(this.state.showMobileEmail!==true){
        mobileView = <EmailList 
                        ref = "emailListRefMobile"
                        getEmailProps={this.getEmailProps} 
                        time = {this.state.time} 
                        handleInitialEmailView={this.handleInitialEmailView} 
                        mid={this.state.mid} 
                        folder={this.state.folder} 
                        handleMobileEmailView={this.handleMobileEmailView}
                      />
      }
      else {
        mobileView = <Email name={this.state.senderName} 
                        subject ={this.state.subject} 
                        body={this.state.body} 
                        date={this.state.date} 
                        attachments={this.state.attachments} 
                        initialEmailView={this.state.initialEmailView}
                        handleInitialEmailView={this.handleInitialEmailView}
                        handleMobileEmailView={this.handleMobileEmailView}
                      />
      }
      const drawer = (
        <div>
            <Button 
                style={{
                marginTop:"10px",
                marginLeft:"32px"
                }}
                component="nav"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={this.showCompose}
                startIcon={<CreateIcon />}
            >
                Compose
            </Button>
            <List component="nav" aria-label="mailbox list">
                <ListItem button selected={this.state.selectedIndex===1} onClick={()=>{this.handleFolder("Inbox",1)}}>
                    <ListItemIcon> <InboxIcon /> </ListItemIcon>
                    <ListItemText primary="Inbox" />
                </ListItem>
                <ListItem button selected={this.state.selectedIndex===2} onClick={()=>{this.handleFolder("Sent",2)}}>
                    <ListItemIcon> <SendIcon /> </ListItemIcon>
                    <ListItemText primary="Sent" />
                </ListItem>
            </List>
            <Divider />
        </div>
    );
    return(
      <div  className={classes.root}>
      <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
             <SessionTimeout 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
      <CssBaseline/>
    
      <AppBar position="fixed" className={classes.appBar}>
     
          <Toolbar>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
          >
              <MenuIcon />
          </IconButton>
          
          <Typography variant="h6" noWrap>
          {process.env.REACT_APP_APP_NAME}
          </Typography>
          <IconButton
              color="inherit"
              aria-label="go home"
              edge="end"
              style={{
                position:'absolute',
                right:'12px',
              }}
              onClick={this.handleHomeButton}
              className={classes.homeButton}
          >
              <HomeIcon />
          </IconButton>
          </Toolbar>
         
      </AppBar>
     
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
          <Drawer
              container={container}
              className={classes.drawer}
              variant="temporary"
              anchor='left'
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
              paper: classes.drawerPaper,
              }}
              ModalProps={{
              keepMounted: true, // Better open performance on mobile.
              }}
          >
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerToggle}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                
              </div>
              {drawer}
          </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
          
          <Drawer
              className={classes.drawer}
              classes={{
              paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
          >
          <Toolbar/>
              {drawer}
          </Drawer>
          </Hidden>
           
           <Hidden smUp implementation="css">
            {this.state.isCompose?<div className={classes.content}><Compose hideCompose={this.hideCompose} name={this.state.userName} email={this.state.email} mid={this.state.mid}/></div> :<div>
            {mobileView}
            </div>}
            </Hidden>
          <Hidden xsDown implementation="css">
          {this.state.isCompose? <div className={classes.content}><Compose hideCompose={this.hideCompose} name={this.state.userName} email={this.state.email} mid={this.state.mid}/></div> 
                              :<div>
                                    <EmailList 
                                          ref = "emailListRef"
                                          getEmailProps={this.getEmailProps} 
                                          handleMobileEmailView={this.handleMobileEmailView} 
                                          handleInitialEmailView={this.handleInitialEmailView} 
                                          time = {this.state.time}  
                                          mid={this.state.mid} 
                                          folder={this.state.folder} 
                                    />
                                  <div>
                                    <Email 
                                          name={this.state.senderName} 
                                          subject ={this.state.subject} 
                                          body={this.state.body} 
                                          date={this.state.date} 
                                          attachments={this.state.attachments} 
                                          initialEmailView={this.state.initialEmailView}
                                          handleInitialEmailView={this.handleInitialEmailView}
                                          handleMobileEmailView={this.handleMobileEmailView}
                                    />
                                  </div>
                              </div>
           }
             </Hidden>
      </div>
  );
    }
    
}

export default withTheme(withStyles(useStyles)(MailboxList));

    