import React, { Component } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number'
import {Typography, Paper, Button, Grid } from "@material-ui/core";
import {TextField, FormControlLabel, Switch, Fade } from '@material-ui/core'
import Attachment from './Attachment'
import axios from 'axios'
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { compose } from '@material-ui/system';
import { NONAME } from 'dns';
import './custom.css';
require('@ocf/oms-widget/public/styles.css')

//.MuiFormControl-root --- for fields background color
//.MuiFormLabel-root --- for label color

const useStyles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    
    marginTop: "60px",
    [theme.breakpoints.up("md")]:{
      width: 'calc(100vw - 285px)',
    },
    [theme.breakpoints.down("sm")]:{
      width: 'calc(100vw - 65px)',
    },

    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor:'#f1f1f1',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
    },
  },
  
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const subjectType = [
  {
    value: 'Claims',
    label: 'Claims',
  },
  {
    value: 'Eligbility',
    label: 'Eligbility',
  },
  {
    value: 'Benefits',
    label: 'Benefits',
  },
  {
    value: 'Providers',
    label: 'Providers',
  },
  {
    value: 'Other',
    label: 'Other',
  }
]

const coverageType = [
  {
    value: 'Vision',
    label: 'Vision',
  }
];

 class Compose extends Component{
  constructor(props){
    super(props)
    this.state={
      mid:"",
      name:"",
      email:"",
      memberId:"",
      coverage:"Vision",
      telNum:"",
      invalidMobile : false,
      bestTime:"",
      subject:NONAME,
      message:"",
      attachmet:[],
      submitButtonDisabled:false
    }
  }
  componentDidMount(){
   
    setTimeout(()=>{
      this.setState({
        mid:this.props.mid,
        name:this.props.name,
        email:this.props.email
      })
    },100)
    
  }

  getAttachmentId=(id) => {
    const att =[];
    id.map((value,index) => {
      att.push({"attachment_id": value})
    })
    //console.log(att)
    this.setState({
       attachmet : att
    })
    // setTimeout(()=>{
    //   //console.log(this.state.attachmet)
    // },100)
  }
  //Form Handlers
  handleMemberId = (event)=>{
    this.setState({
      memberId:event.target.value
    })
  }
  handleCoverageType = (event) => {
    this.setState({
      coverage:event.target.value
    })
  };
  handleTelNum= (value) => {
    if(value.length === 17){
      this.setState({
        invalidMobile:false,
        submitButtonDisabled:false
      })
    }
    else {
      this.setState({
        invalidMobile:true,
        submitButtonDisabled:true
      })
    }
      this.setState({
        telNum:value
      })
  }
  handleBestTime = (event) => {
    this.setState({
      bestTime:event.target.value

    })
  }
  handleSubject= (event) =>{
    this.setState({
      subject:event.target.value
    })
  }
  handleMessage =(event)=>{
    this.setState({
      message: event.target.value
    })
  }
  //disable submit button until upload is complete
  handleSubmitButtonDisabled = (value)=>{
    this.setState({
      submitButtonDisabled: value
    })
  }

  handleCancel= () => {
    this.setState({
      memberId:"",
      telNum:"",
      bestTime:"",
      subject:"",
      attachmet:[],
      message:"",
    })
    this.refs.attachmentRef.handleUploadComplete(false)
  }

  
  handleSubmit= (event) => {
    event.preventDefault()
    // console.log("subject: " + this.state.subject + " body: " + this.state.message + " telNum: " + this.state.telNum +
    // " bestTime: "+ this.state.bestTime + " coverage: " + this.state.coverage +" otehr Id: " + this.state.memberId )
      const property = {
        "memberId": this.state.memberId,
        "telNum": this.state.telNum,
        "bestTime": this.state.bestTime,
        "coverage": this.state.coverage,
      }
    
      let messagebody = {
          "sender" : {
          "mid" : this.state.mid
          },
          "subject": this.state.subject,
          "body": this.state.message,    
          "recipients": {
            "recipient": {
              "mid": process.env.REACT_APP_RECIEVER_MID         
            } 
            },
          "attachments":{
            "attachment":this.state.attachmet
          }, 
          "attributes": JSON.stringify({
            "agent_display": {
              "member_id": this.state.memberId,
              "member_name": this.state.name,
              "telephone_number": this.state.telNum,
              "best_time": this.state.bestTime
              }
            }),
          "properties": JSON.stringify(property)
        } 
        //console.log("message: " + JSON.stringify(messagebody))
        const formData = new FormData();
        formData.append("message",JSON.stringify(messagebody))
        axios.post(process.env.REACT_APP_OMS_MESSAGE_URL,formData, {
          headers: {
          'Authorization': "Bearer " + sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'No-Store'
          }
        })
        .then(res => {
          //console.log('Response received!')
          //console.log(res.data)
          alert("Message Sent Successfully")
            this.setState({
              memberId:"",
              telNum:"",
              bestTime:"",
              subject:"",
              attachmet:[],
              message:"",
            })
            this.refs.attachmentRef.handleUploadComplete(false)
        })
        .catch(error => {
          //console.log('Printing error')
          //console.log(error);
          alert("There was an error while sending message")
          }); 
        }

  render(){
    const {classes} = this.props;
    return(
      <div>
          <CssBaseline />
        <div className={classes.layout }>
         <form id="form" onSubmit={this.handleSubmit.bind(this)}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Ask a Question
            </Typography>
            <br/>
           <React.Fragment> 
               <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                <Typography noWrap="true">Name : {this.state.name}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography noWrap="true">Email : {this.state.email}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                        required
                        type="Number"
                        inputProps={{ min: "0"}}
                        value={this.state.memberId}
                        onChange={this.handleMemberId}
                        variant="filled"
                        id="memberId"
                        name="memberId"
                        label="Member Id"
                        fullWidth
                      />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                  disabled
                  id="coverageType"
                  select
                  fullWidth
                  label="Coverage Type"
                  value={this.state.coverage}
                  onChange={this.handleCoverageType}
                  SelectProps={{
                    native: true,
                  }}
                  variant="filled"
                >
                <option aria-label="None" value="" />
                  {coverageType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                   <MuiPhoneNumber 
                   required
                   error={this.state.invalidMobile}
                   data-cy="user-phone"
                   variant="filled"
                   defaultCountry={'us'}
                   value={this.state.telNum}
                   onChange={this.handleTelNum.bind(this)} 
                   id="telNum"
                   label="Telephone Number"
                   fullWidth
                   helperText = {this.state.invalidMobile? "Please enter a valid 10 digit number":""}
                   />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="filled"
                    id="bestTime"
                    label="Best time to reach you"
                    value={this.state.bestTime}
                    onChange={this.handleBestTime}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  required
                  id="subject"
                  select
                  fullWidth
                  label="Subject"
                  value={this.state.subject}
                  onChange={this.handleSubject}
                  SelectProps={{
                    native: true,
                  }}
                  variant="filled"
                >
                <option aria-label="None" value="" />
                  {subjectType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="filled"
                    multiline
                    rows={10}
                    id="message"
                    name="message"
                    label="How can we help you?"
                    value={this.state.message}
                    onChange={this.handleMessage}
                    fullWidth
                  />
                </Grid>
                    <Grid item xs={6}>
                      <Typography paragraph>
                        Maximum combined file size : 5mb
                        <br/>
                        Formats accepted : .jpg .png .jpeg .pdf
                        <br/>
                        Scanned Documents - Recommended Resolutions : 300 dpi
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Attachment ref="attachmentRef" getAttachmentId={this.getAttachmentId} handleSubmitButtonDisabled={this.handleSubmitButtonDisabled} />
                    </Grid>
                  <Grid item xs={12}>
                    <Typography paragraph>
                      We will respond to messages sent through this secure system within two business days. We will 
                      notify you of any decisions about benifits or appeals through standard methods.
                      You will not recieve benifit or appeal decisions not the other garuntees of coverage through 
                      this message system. 
                    </Typography>
                    <Typography paragraph>
                      *Required Fields.
                      </Typography>
                      <Typography paragraph>
                      **If we need more details, we may call you. If you have an international phone number, a telephone
                      typewriter or a telecommunications device for the deaf; we will ask you to call us. We will not 
                      share your telphone number with outside parties. 
                      </Typography>
                  </Grid>
                </Grid>
            </React.Fragment>  
            <React.Fragment>
              <Grid container direction="row" justify="center"  alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleCancel}
                    size="large"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className={classes.button}
                    disabled={!(this.state.memberId && this.state.telNum && !this.state.invalidMobile 
                              && this.state.subject && this.state.message && !this.state.submitButtonDisabled) }
                  >
                    Submit
                  </Button>
                </Grid>
            </React.Fragment>
          </Paper>
          </form> 
        </div>
      </div>
  );
  }
 }

export default withStyles(useStyles)(Compose);


