import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SessionTimeout extends React.Component {
    constructor(props){
        super(props)
    }
    render(){
        return(
            <Dialog
            open={this.props.showModal}
            onClose={this.props.handleClose}
            aria-labelledby="timeout"
            aria-describedby="timeoutDescription"
            >
                <DialogTitle id="timeout">{"You Have Been Idle!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="timeout">
                        You Will Get Timed Out. You want to stay?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleLogout} color="primary">
                          Logout
                        </Button>
                        <Button onClick={this.props.handleClose} color="primary" autoFocus>
                         Stay
                        </Button>
                    </DialogActions>
        </Dialog>
        )
    }

} 