import React, {Component} from 'react'
import axios from 'axios'
// import qs from 'querystring'
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles} from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

class Attachment extends Component{
  constructor(props){
        super(props);
        this.state = {
            open: false,
            files: [],
            attachment_id:[],
            uploadComplete:false,
            severity:"",
            alertMessage:""
        };
    }
    handleChange(files){
        this.setState({
          files: files
        });
      }

    handleClose() {
        this.setState({
            open: false
        });
    }
    handleSave= (files) => {
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files,
            open: false
        });
        setTimeout( () => {
            const formData = new FormData();
            //console.log(this.state.files)
            this.state.files.map((value,i) =>{
                formData.append("File " + (i +1) ,(value));
            })
            this.props.handleSubmitButtonDisabled(true);
            axios.post(process.env.REACT_APP_OMS_ATTACHMENTS_URL,formData, {
            params:{
                'space_id': process.env.REACT_APP_SPACE_ID,
                'virusscan': true
            },
            headers: {
                'Authorization': "Bearer " + sessionStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
                'Cache-Control': 'No-Store'
        }})
            .then(res => {
            //console.log('Response received!')
            //console.log(res.data)
            const arr = [];
            res.data.attachment.map((value,i)=>{
                arr.push(value.id)
            })
            //console.log(arr)
            this.setState({
                attachment_id: arr
            })
            this.props.getAttachmentId(this.state.attachment_id)
            this.props.handleSubmitButtonDisabled(false);
            this.setState({
                severity: "success",
                alertMessage: "Upload complete",
                uploadComplete:true
            })
            })    
            .catch(error => {
                if(error.response){
                    if(error.response.data.virusscan[0].virusscan_status === "INFECTED"){
                        this.setState({
                            severity: "error",
                            alertMessage: "This file contains virus, please select another file",
                            uploadComplete:true
                        })
                    }
                    else {
                        this.setState({
                            severity: "error",
                            alertMessage: "The file didn't get uploaded properly, please try again",
                            uploadComplete:true
                        })
                    }
                }
            });

            
        }, 100);
        
    }

    handleOpen() {
        this.setState({
            open: true,
        });
    }

    handleUploadComplete(value) {
        this.setState({
            uploadComplete:value
        })
    }
    

    render() {
        return (
            <div>
                <Grid container  justify="flex-end" alignItems="center">
                    <Grid item >
                        <Typography paragraph>
                        UPLOAD DOCUMENTS
                        </Typography>
                    </Grid>
                    <Grid container  justify="flex-end" alignItems="center">
                    {/* <button onClick={this.tokenHandler}>token</button> */}
                        <Button onClick={this.handleOpen.bind(this)}
                            variant="contained"
                            color="primary"
                            size="medium"
                        >
                        Upload
                        </Button>
                    </Grid>    
                </Grid>
                <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/gif', 'application/pdf']}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                />
                {this.state.uploadComplete?<Grid container  justify="flex-end" alignItems="center"><Alert severity={this.state.severity} >{this.state.alertMessage}</Alert></Grid>:null}
                
            </div>
        );
  }
}

export default Attachment;

                
                
                
