import React from 'react';
import {Typography, Toolbar, Divider, Paper} from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles} from '@material-ui/core/styles';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
// import Widget from '@ocf/oms-widget'
// import { typography } from '@material-ui/system';
// import Axios from 'axios';
// import { callbackify } from 'util';
// require('@ocf/oms-widget/public/styles.css')

const useStyles = (theme) => ({
  imgroot:{
    display: 'inline-block',
    flexWrap: 'wrap',
    margin: "10px",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerWidth:{
    width:"55vw",
    [theme.breakpoints.down('xs')]: {
      width:"100vw"
    }
    
  },
  layout: {
    width: "calc(100vw-325px)",
    position:"-ms-page",
    left:"auto",
    marginLeft: '345px',
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft:'20px'
      // width:"-webkit-fill-available"
    }
  }, 
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
   // necessary for content to be below app bar
   toolbar: theme.mixins.toolbar,
});

class Email extends React.Component {
  constructor(props){
    super(props)
    this.state={
           Files:[],
           open:false,
           value:null
    }
  }
  //to remove the current email showing after unmount of compoenent
  componentWillUnmount(){
    this.props.handleInitialEmailView(false)
  }
  handleOpen = (value) => {
    this.setState({
      open:true,
      value:value
    })
    
    window.open(value)
  };
  handleClose = () => {
    this.setState({
      open:false
    })
  };
 

 render(){
  const {classes} = this.props;
  const name = this.props.name
  const subject = this.props.subject
  const body = this.props.body
  const date = this.props.date
  const attachments = this.props.attachments
  //console.log(attachments.length)
  const initialEmailView = this.props.initialEmailView
  return(
    <div>
    {initialEmailView?<div>
      <CssBaseline />
      <main className={classes.layout }>
      <Toolbar/>
      <div>
      <Typography variant='subtitle1'>
       Sent:     {date}<br/>
       From:     {name}<br/>
       Subject:  {subject}

      </Typography>
      <Divider className={classes.drawerWidth}/>
      </div>
      <Typography 
      >
      {/* <br/> */}
        {ReactHtmlParser(body)}
        <br/><br/>
      </Typography>
      <div>
        {(attachments.length !== 0) ?<div>
                                      <Divider className={classes.drawerWidth} />
                                        <Typography variant='subtitle1'>
                                          Attachments
                                        </Typography>
                                     <div>
                                      {attachments.map((value,i)=>{
                                        return (
                                        
                                          <Paper elevation={1} className={classes.imgroot}>
                                                <img src={value} style={{margin:"10px"}} height="100px" width ="100px" onError={(e)=>{e.target.onerror = null; e.target.src="./attachment.PNG"}} onClick={()=>this.handleOpen(value)}/>
                                          </Paper>
                                        )
                                      })}
                                     </div>
                                     </div>:null}
 
      </div>
      </main>
      </div>:null}
      </div>
 )}
}

export default withStyles(useStyles)(Email);

