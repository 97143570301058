import React from 'react';
import './App.css';
import MailboxList from './MailboxList'
import { CssBaseline, withStyles} from "@material-ui/core";

 class App extends React.Component{
  constructor(props){
    super(props)
  } 

  render(){
    return (
      <div>
        <CssBaseline />
          <MailboxList />
      </div>
    ) 
  }
}

export default App;